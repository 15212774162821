$(document).ready(function () {

  // $(window).resize(function(){

  //   if ($(window).width() <= 991) {   
  //     $('.header-menu .menu-item-has-children > a').hover(function (e) {
  //       e.preventDefault();
  //       $(this).parent().find('.sub-menu').stop().slideToggle();
  //       $(this).parent().siblings().find('.sub-menu').slideUp();
  //     });
  //   }
  // });
 
  $('.ambasadors_slider').owlCarousel({
    items: 1,
    autoplay: true,
    loop: true,
    rtl: true,
    dots: false,
    nav: false,
    smartSpeed: 1000,
    autoplayTimeout: 4000,
  });
  $('.partners_slider').owlCarousel({
    items: 3,
    autoplay: true,
    loop: true,
    rtl: false,
    dots: false,
    nav: false,
    smartSpeed: 1000,
    autoplayTimeout: 4000,
  });

});

$(window).resize(function () {
  $('.menu-item-has-children > a').parent().siblings().find('.sub-menu').slideUp();
});




$(document).ready(function (e) {
  $(document).on('click', 'path[class="provinceMap"]', function () {
    $('path[class="provinceMap"]').removeAttr("style");
    $('text').css("display", "none");

    $(this).css('fill', "#45C0EF");

    var id = this.id;
    var number = document.getElementById(id + "Number");

    $(number).css("display", "block");
    $('#provinceSelect option').removeAttr('selected')
    $('#provinceSelect option').removeProp('selected')
    $('#provinceSelect option[value="' + id + '"]').attr('selected', 'selected');
    $('#provinceSelect option[value="' + id + '"]').prop('selected', 'selected');

  });
});


setTimeout(function () {
  $('.menu-item-has-children > a').find('.sub-menu').css('display', 'block');
}, 500);

$(document).ready(function () {
  if ($('.imagesWithDescriptionContainer > .reverseOnMobile > .descriptionBlock').is(':empty')) {
    $('.reverseOnMobile:last-child .descriptionBlock').remove();
    $('.reverseOnMobile:last-child .imageBlock').remove();
  }
  $('.frontPageSliderCaptionContainer > a:empty ').remove();
});